import axiosInstance from "@/axios";

class renovacaoController {
  async ObterParametrosRenovacaoPorSeguradora() {
    return await axiosInstance
      .get("/api/ParametroRenovacaoPorModalidade/ObterParametrosRenovacaoPorSeguradora")
      .then(response => response.data.Response);
  }
  async ObterIndices() {
    return await axiosInstance
      .get("/api/Parametros/ObterIndices")
      .then(response => response.data.Response);
  }
  async ObterModalidadesAtivas() {
    return await axiosInstance
      .get(`/api/Core/ObterModalidadesAtivas`)
      .then(response => response.data.Response);
  }
  async ObterListaLogAuditoriaModalidade() {
    return await axiosInstance
      .get(`/api/Parametros/ObterLogAuditoriaParametrosRenovacaoConfiguracaoPorModalidade`)
      .then(response => response.data.Response);
  }
  async ObterListaLogAuditoriaConfig() {
    return await axiosInstance
      .get(`/api/Parametros/ObterLogAuditoriaParametrosRenovacaoConfiguracao`)
      .then(response => response.data.Response);
  }
  async ObterListaPerfisUsuario() {
    return await axiosInstance
      .get(`/api/UserProfile/GetProfileByCompanyId?userInfoTypeId=2`)
      .then(response => response.data.Response);
  }
  async obterListaTagsAutomaticas() {
    return await axiosInstance
      .get(`/api/Core/ObterTagsAutomaticas?tipoTag=3`)
      .then(response => response.data.Response);
  }
  async buscarListaPerfis() {
    return await axiosInstance
      .get(`/api/ParametroRenovacaoPorModalidade/BuscarPerfisDeUsuario`)
      .then(response => response.data.Response);
  }
  async buscarTemplateEmail() {
    return await axiosInstance
      .get(`/api/Parametros/ObterEmailTemplate?emailTemplate=39`)
      .then(response => response.data.Response);
  }
  async AtualizarParametroRenovacao(data) {
    return await axiosInstance
      .post(
        `/api/ParametroRenovacaoPorModalidade/AtualizarParametroRenovacao`,
        data
      )
      .then(response => response.data.Response);
  }
  async removerPerfil(data) {
    return await axiosInstance
      .post(
        `/api/ParametroRenovacaoPorModalidade/RemoverPerfilDeUsuarioEnvioEmail`,
        data
      )
      .then(response => response.data.Response);
  }
  async AtualizarEmailTemplate(data) {
    return await axiosInstance
      .post(`/api/Parametros/AtualizarEmailTemplate`, data)
      .then(response => response.data.Response);
  }
  async adicionarPerfil(data) {
    return await axiosInstance
      .post(
        `/api/ParametroRenovacaoPorModalidade/AdicionarPerfilDeUsuarioEnvioEmail`,
        data
      )
      .then(response => response.data.Response);
  }
}

export default new renovacaoController();
